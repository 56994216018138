'use strict'

import axios from 'axios'
import store from '@/store'
import router from '@/router'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  withCredentials: true // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  async (error) => {
    // Do something with response error
    if (error.response && error.response.status === 401) {
      await store.dispatch('setUser', null)
      await router.push({ name: 'Login' })
    }
    return Promise.reject(error)
  }
)

_axios.CancelToken = axios.CancelToken
_axios.isCancel = axios.isCancel

export default {
  install: function (app, options) {
    app.config.globalProperties.axios = _axios
    window.axios = _axios
    Object.defineProperties(app.config.globalProperties, {
      axios: {
        get () {
          return _axios
        }
      },
      $axios: {
        get () {
          return _axios
        }
      }
    })
  }
}
