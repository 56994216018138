import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Main',
    meta: {
      header: true,
      isNeedAuth: true
    },
    component: () => import('../views/Main.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      header: false,
      isNeedAuth: false
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/registration',
    name: 'Registration',
    meta: {
      header: false,
      isNeedAuth: false
    },
    component: () => import('../views/Registration.vue')
  },
  {
    path: '/email-confirm',
    name: 'EmailConfirm',
    meta: {
      header: false,
      isNeedAuth: false
    },
    component: () => import('../views/EmailConfirm.vue')
  },
  {
    path: '/restore',
    name: 'RestorePassword',
    meta: {
      header: false,
      isNeedAuth: false
    },
    component: () => import('../views/RestorePassword.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      header: false,
      isNeedAuth: false
    },
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/deals',
    name: 'Deals',
    meta: {
      header: true,
      isNeedAuth: true
    },
    component: () => import('../views/Deals.vue')
  },
  {
    path: '/help',
    name: 'Help',
    meta: {
      header: true,
      isNeedAuth: true
    },
    component: () => import('../views/Help.vue')
  },
  {
    path: '/instruments',
    name: 'Instruments',
    meta: {
      header: true,
      isNeedAuth: true
    },
    component: () => import('../views/Instruments.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      header: true,
      isNeedAuth: true
    },
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      header: false,
      isNeedAuth: false
    },
    component: () => import('../views/404.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  if (to.meta.isNeedAuth && !store.state.user) next({ name: 'Login' })
  else next()
})

export default router
