import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: false })

export default createStore({
  state: {
    user: null,
    openedModal: null,
    openedModalProperty: null
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
    },
    setOpenedModal (state, payload) {
      state.openedModal = payload
    },
    setOpenedModalProperty (state, payload) {
      state.openedModalProperty = payload
    }
  },
  actions: {
    setUser ({ commit }, payload) {
      commit('setUser', payload)
    },
    setOpenedModal ({ commit }, payload) {
      commit('setOpenedModal', payload)
    },
    setOpenedModalProperty ({ commit }, payload) {
      commit('setOpenedModalProperty', payload)
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    key: 'partners_cabinet_store',
    storage: process.env.NODE_ENV === 'production'
      ? {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
      : {
        getItem: key => localStorage.getItem(key),
        setItem: (key, value) => localStorage.setItem(key, value),
        removeItem: key => localStorage.removeItem(key)
      }
  })]
})
