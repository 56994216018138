import getPathRequest from '@/assets/helpers/get_path_request'
import { mapActions } from 'vuex'

const common = {
  methods: {
    ...mapActions([
      'setOpenedModal',
      'setOpenedModalProperty'
    ]),
    openModal (id, property) {
      this.setOpenedModal(id)
      this.setOpenedModalProperty(property)
    },
    closeModal () {
      this.setOpenedModal(null)
      this.setOpenedModalProperty(null)
    },
    getPathRequest
  }
}

export default common
