<template>
  <a href="javascript:void(0);" @click="logout" class="notify__link" title="Выйти">
    <svg class="icon icon-logout">
      <use xlink:href="#icon-logout"></use>
    </svg>
  </a>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Logout',
  methods: {
    ...mapActions([
      'setUser'
    ]),
    logout () {
      this.$axios.get(this.getPathRequest('logout'))
        .then(() => {
          this.setUser(null)
          this.$router.push({ name: 'Login' })
        })
        .catch(error => {
          this.openModal('ErrorModal', {
            title: 'Ошибка выхода из учётной записи',
            text: error.response.data.message
              ? error.response.data.message
              : 'Во время выхода из учётной записи произошла ошибка. Пожалуйста, попробуйте снова или обратитесь к нам в тех. поддержку.'
          })
        })
    }
  }
}
</script>
