// import serializeObjectToQuery from '@/assets/helpers/serialize_query_url'
/**
 * Функция получения адреса запроса по идентификатору
 * @param id Идентификатор адреса
 * @param payload Уникальные данные для генерации адреса (например, id пользователя)
 * @returns {string}
 */

export default function getPathRequest (id, payload = {}) {
  const cabinetUrl = window.envVariables.BACK_URL.replace(/\/$/, '')
  const urlList = {
    // проверка данных для входа
    login: cabinetUrl + '/partners/login',
    // запрос на разлогинивание
    logout: cabinetUrl + '/partners/logout',
    // регистрация пользователя
    registration: cabinetUrl + '/partners/register',
    // проверка подтверждённой почты
    email_confirm: cabinetUrl + '/partners/email-confirm',
    // запрос на сброс пароля
    reset_password_request: cabinetUrl + '/partners/reset-password-request',
    // запрос на смену пароля
    reset_password_confirm: cabinetUrl + '/partners/reset-password-confirm',
    // запрос данных пользователя
    user_info: cabinetUrl + '/partners/info',
    // запрос на обновление данных пользователя
    user_update: cabinetUrl + '/partners/update',
    // запрос баланса пользователя
    balance_info: cabinetUrl + '/balance/info'
  }
  return urlList[id] ? urlList[id] : cabinetUrl
}
