<template>
  <transition name="modal">
    <div class="modal-overlay">
      <div class="modal-background" @click="closeModal"><!-- --></div>
      <div class="modal-dialog">
        <div class="container-outer container-outer_width-720">
          <div class="content-block content-block_m-0 content-block_transparent">
            <div class="content-block__body">
              <div class="content-block__body position-relative">
                <slot name="icon">
                  <div class="popup__icon popup__icon_attention">
                    <svg class="icon icon-attention"><use xlink:href="#icon-attention"></use></svg>
                  </div>
                </slot>
                <a href="javascript:void(0);" class="popup__close d-block" @click="closeModal">
                  <svg class="icon icon-close"><use xlink:href="#icon-close"></use></svg>
                </a>

                <slot name="header">
                  <div class="popup__header">
                    <h4 class="h4 mb-3">Стандартный заголовок</h4>
                  </div>
                </slot>

                <slot name="body">
                  <div class="popup__section">
                    <p>Стандартное описание.</p>
                  </div>
                </slot>

                <slot name="footer">
                  <div class="popup__section mt-5 py-0">
                    <div class="row">
                      <slot name="buttons">
                        <div class="col-12 col-md-6 popup_pb-10">
                          <button class="button" @click="closeModal">Закрыть</button>
                        </div>
                      </slot>
                    </div>
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  created () {
    document.querySelector('body').style.overflow = 'hidden'
  },
  beforeUnmount () {
    document.querySelector('body').style.overflow = ''
  }
}
</script>

<style lang="scss" scoped>
.modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    margin-top: 0 !important;
    z-index: 100;
  }
  &-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 0.3);
    z-index: 1;
  }
  &-dialog {
    position: relative;
    padding: 15px 0;
    background-color: #FFFFFF;
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
    max-height: 100%;
    overflow-y: auto;
    z-index: 2;
  }
}
</style>
