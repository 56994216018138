<template>
  <svg-sprite />
  <notifications class="notify" position="top center" />
  <app-header />
  <router-view/>
  <component
    v-if="openedModal"
    :is="openedModal"
    v-bind="openedModalProperty"
  />
</template>

<script>
import { mapState } from 'vuex'
import SvgSprite from '@/components/common/SvgSprite'
import AppHeader from '@/components/common/Header'
import ErrorModal from '@/components/modals/ErrorModal'
import SuccessModal from '@/components/modals/SuccessModal'

export default {
  name: 'App',
  components: {
    SvgSprite,
    AppHeader,
    ErrorModal,
    SuccessModal
  },
  computed: {
    ...mapState([
      'openedModal',
      'openedModalProperty'
    ])
  }
}
</script>

<style lang="scss">
.notify {
  .vue-notification.warn {
    font-size: 1rem;
    color: #000000;
    background: #fc0;
    border: 1px solid #ffffff;
  }
}
</style>
