<template>
  <header class="header" v-if="isShowHeader">
    <div class="header__container justify-content-between d-flex">
      <a href="/" class="logo header__logo">
        <svg class="icon icon-logo_small">
          <use xlink:href="#icon-logo_small"></use>
        </svg>
      </a>
      <a
        href="javascript:void(0);"
        class="header__burger"
        :class="{ active: openMenu }"
        @click="switchMenu"
      >
        <svg class="icon icon-burger">
          <use xlink:href="#icon-burger"></use>
        </svg>
        <svg class="icon icon-clear">
          <use xlink:href="#icon-clear"></use>
        </svg>
      </a>
      <nav class="header__nav">
        <template v-for="(item, index) in menuList">
          <router-link
            v-if="!item.outer"
            :key="'menu_item-' + index"
            :to="item.href"
            class="header__link"
            @click="item.before ? item.before(navigate, $event) : navigate"
            v-slot="{ navigate }"
          >
            {{ item.text }}
          </router-link>

          <a
            v-else
            :key="'menu_item-' + index"
            :href="item.href"
            class="header__link"
          >
            {{ item.text }}
          </a>
        </template>
      </nav>
      <div class="d-flex justify-content-end align-items-center position-relative">
        <div class="header__notify notify">
          <div class="notify__link-wrapper">
            <logout></logout>
          </div>
        </div>
      </div>
    </div>
    <div
      class="header__modal-menu"
      :class="{ active: openMenu }"
      @click="checkMenuClick"
    >
      <template v-for="(item, index) in menuList">
        <router-link
          v-if="!item.outer"
          :key="'menu_item-' + index"
          :to="item.href"
          v-slot="{ navigate }"
          class="link"
          @click="item.before ? item.before(navigate, $event) : navigate()"
        >
          {{ item.text }}
        </router-link>
        <a
          v-else
          :key="'menu_item-' + index"
          :href="item.href"
          class="link"
        >
          {{ item.text }}
        </a>
      </template>
    </div>
  </header>
</template>

<script>

import Logout from './Logout'

export default {
  name: 'Header',
  data () {
    return {
      menuList: [
        {
          href: '/instruments',
          text: 'Инструменты',
          outer: false
        },
        // {
        //   href: '/deals',
        //   text: 'Все сделки',
        //   outer: false
        // },
        {
          href: '/settings',
          text: 'Настройки',
          outer: false
        },
        {
          href: 'https://help.nalozhka.ru/',
          text: 'Помощь',
          outer: true
        }
      ],
      openMenu: false
    }
  },
  components: {
    Logout
  },
  computed: {
    isShowHeader () {
      return this.$route.meta.header
    }
  },
  methods: {
    switchMenu () {
      document.querySelector('body').style.overflow = this.openMenu ? '' : 'hidden'
      this.openMenu = !this.openMenu
    },
    checkMenuClick (event) {
      if (event.target.classList.contains('link')) this.switchMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  min-width: auto;
}

.header__modal-menu {
  .link.active {
    pointer-events: none;
  }
}
</style>
<style>
.header + * {
  margin-top: 60px!important;
}
@media (max-width: 991.98px) {
  .header + * {
    margin-top: 120px!important;
  }
}
</style>
