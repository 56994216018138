import { createApp } from 'vue'
import App from './App.vue'
import axios from './plugins/axios'
import router from './router'
import store from './store'
import common from './mixins/common'
import VueClipboard from 'vue3-clipboard'
import Notifications from '@kyvg/vue3-notification'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(axios)
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true
})
app.use(Notifications)
app.mixin(common)
app.mount('#app')
