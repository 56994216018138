<template>
  <div class="is-invisible" v-html="svgSprite"></div>
</template>

<script>
export default {
  name: 'svgSprite',
  data () {
    return {
      svgSprite: String
    }
  },
  created () {
    const ajax = new XMLHttpRequest()
    ajax.open('GET', window.envVariables.CDN_URL.toString().replace(/\/$/, '') + '/img/sprite.svg', true)
    ajax.send()
    ajax.onload = () => {
      this.svgSprite = ajax.responseText
    }
  }
}
</script>

<style lang="scss" scoped>
  .is-invisible {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
  }
</style>
