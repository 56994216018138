<template>
  <modal>
    <template v-slot:icon>
      <div class="popup__icon popup__icon_attention">
        <svg class="icon icon-attention"><use xlink:href="#icon-attention"></use></svg>
      </div>
    </template>
    <template v-slot:header>
      <h4 class="h4 mb-3">{{ title }}</h4>
    </template>
    <template v-slot:body>
      <p>{{ text }}</p>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/common/Modal'

export default {
  name: 'SuccessModal',
  components: {
    Modal
  },
  props: {
    title: {
      type: String,
      default: 'Действие успешно завершено.'
    },
    text: {
      type: String,
      default: 'Вы может продолжать работу.'
    }
  }
}
</script>
